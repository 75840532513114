/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 14:28:47
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-10 17:55:38
 * @Description:  企业项目
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse, Page, TableData } from '@/models/common'
import { Project, type IProject } from '@/models/project'

// 项目列表入参
export type ProjectListOption = Page & {
  enterpriseId?: string
  name?: string
  auditStatus?: string
  type?: string
  provinceCode?: string
  cityCode?: string
  districtCode?: string
}
/**
 * 获取项目列表
 * @param params ProjectListOption
 * @returns
 */
export function getProjectList(params: ProjectListOption) {
  return http.request<BasicResponse<TableData<Project>>>({
    url: '/community',
    method: 'get',
    params: params,
  })
}

/**
 * 添加项目
 * @param data Project
 * @returns
 */
export function postProject(data: IProject) {
  return http.request<BasicResponse<number>>({
    url: '/community',
    method: 'post',
    data: data,
  })
}

/**
 * 修改项目
 * @param data Project
 * @returns
 */
export function putProject(id: string, data: IProject) {
  return http.request<BasicResponse<number>>({
    url: `/community/${id}`,
    method: 'put',
    data: data,
  })
}

/**
 * 项目详情
 * @param id 项目ID
 * @returns
 */
export function getProjectDetail(id: string) {
  return http.request<BasicResponse<Project>>({
    url: `/community/${id}`,
    method: 'get',
  })
}

/**
 * 删除项目
 * @param id 项目ID
 * @returns
 */
export function deleteProject(id: string) {
  return http.request<BasicResponse<number>>({
    url: `/community/${id}`,
    method: 'delete',
  })
}
